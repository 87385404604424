.new-menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: transparent;
  overflow: visible;
  padding-bottom: 100px;
}

.new-menu .page-head {
  margin-bottom: 0;
}

.newmenu-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

.embla {
  width: 100%;
}

.embla__container {
  display: flex;
  flex-direction: column;
}

.embla__slide {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  margin-top: 20px;
}

.newmenu-chunk-anchor {
  position: absolute;
  width: 10px;
  top: -80px;
  left: 0;
  height: 1px;
}

.newmenu-chunk {
  display: flex;
  flex-wrap: wrap;
  padding: 0 7vw;
  gap: 6vw;
}

.newmenu-chunk-item {
  display: flex;
  flex-direction: column;
  width: calc(50% - 3.5vw);
}

.newmenu-chunk-title {
  padding: 0 6%;
  margin-bottom: 1rem;
  color: #000;
  font-size: 24px;
  font-family: CraftworkGrotesk-Heavy;
  text-transform: uppercase;
}

.newmenu-chi-image {
  width: 38vw;
  height: 38vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}

.newmenu-chi-title {
  display: flex;
  align-items: flex-start;
  /* margin-top: -10px; */
  color: #000;
  font-size: 18px;
  font-family: CraftworkGrotesk-Bold;
}

.newmenu-chi-desc {
  display: flex;
  flex: 1;
  height: 80px;
  margin-top: 8px;
  color: #000;
  font-size: 14px;
  font-family: CraftworkGrotesk-Medium;
}

.newmenu-chi-details {
  margin-top: 15px;
}

.newmenu-chi-detailsrow {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  padding: 0;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
}

.newmenu-chi-dr-add:active  {
  color: #FFFFFF;
  background-color: #CE3939;
}

.newmenu-chi-dr-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  gap: 4px;
}

.newmenu-chi-dr-left {
  color: #000;
  font-size: 13px;
  font-family: CraftworkGrotesk-Medium;
}

.newmenu-chi-dr-right {
  padding: 6px 10px;
  background-color: #CE3939;
  color: #FFF;
  text-wrap: nowrap;
  font-size: 12px;
  font-family: CraftworkGrotesk-Medium;
}

.newmenu-chi-dr-actions {
  display: flex;
  width: 100%;
}

.newmenu-chi-dr-add {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 35px;
  border: none;
  margin-top: 10px;
  padding-bottom: 3px;
  border: 2px solid #CE3939;
  background-color: transparent;
  color: #CE3939;
  font-size: 20px;
  font-family: CraftworkGrotesk-Medium;
  box-sizing: border-box;
  animation: fadeIn 0.2s ease;
}

.newmenu-chi-dr-add.fill {
  color: #FFFFFF;
  background-color: #CE3939;
}

.newmenu-chi-dr-qty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  height: 35px;
  border: none;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 3px;
  background-color: transparent;
  color: #CE3939;
  font-size: 20px;
  font-family: CraftworkGrotesk-Medium;
  box-sizing: border-box;
  animation: fadeIn 0.2s ease;
}

.menu-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 200px;
  /* background: radial-gradient(82.62% 82.62% at -31.9% 17.38%, #fff7d9 0%, rgba(255, 186, 186, 0) 100%),
    linear-gradient(0deg, #fff6e0, #fff6e0); */
  overflow: visible;
}

.agenda-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-bottom: 30px;
  background-color: #00000077;
  z-index: 1000;
}

.agenda-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 80%;
  padding: 30px 20px 20px 20px;
  margin-bottom: 70px;
  background: #432a2a;
}

.agenda-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.agenda-row-desc {
  margin-left: 10px;
  color: #ffffff;
  font-size: 20px;
  font-family: MazzardL-Regular;
}

.menu-page .c-action-divider {
  margin: 20px 0;
}

.menu-page .page-head {
  margin-bottom: 0;
}

.quick-links-wrapper {
  position: sticky;
  top: 0;
  display: block;
  width: 100vw;
  padding-left: 2%;
  box-sizing: border-box;
  z-index: 100;
  transition: 0.1s ease;
}

.quick-links-wrapper.fixed.new {
  background-color: #FCFAF0;
}

.quick-links-wrapper.fixed {
  background-color: #2b1e1dF2;
  padding-left: 0;
  transition: 0.1s ease;
}

.quick-links-wrapper.fixed>.quick-links {
  margin: 4px 0 4px 0;
  padding: 4px;
}

.quick-links {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px 0 10px 0;
  padding-left: 2%;
  overflow-x: auto;
  box-sizing: border-box;
  overscroll-behavior-y: none;
  overscroll-behavior: none;
}

::-webkit-scrollbar {
  display: none;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  margin: 5px;
  min-width: fit-content;
  border-radius: 2px;
  border: 1px solid #1275BB;
  background-color: #1275BB;
  outline: none;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 15px;
  font-family: CraftworkGrotesk-Medium;
  overflow-y: visible;
}

.link:active {
  outline: 3px solid #1275BB;
  outline-offset: 1px;
}

.menu-chunks {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

.menu-chunks:first-of-type {
  margin-top: 0;
}

.menu-chunk-title {
  display: flex;
  width: 90%;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 35px;
  font-family: MazzardL-Semibold;
  text-align: center;
  box-sizing: border-box;
}

.menu-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.ml-items {
  display: flex;
  margin-top: 1vh;
}

.ml-item {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 20px;
  margin-bottom: 10px;
  background: linear-gradient(91.75deg, #432a2a 9.37%, #502727 100.38%);
  box-sizing: border-box;
  border-radius: 4px;
}

.ml-item.active {
  flex-direction: column-reverse;
}

.ml-item.active>.ml-item-left {
  width: 100%;
}

.ml-item-left {
  width: 65%;
  height: 100%;
}

.mli-title {
  color: #ffffff;
  font-size: 25px;
  font-family: MazzardL-Semibold;
}

.mli-description {
  margin-top: 5px;
  margin-bottom: 15px;
  color: #ffffff;
  font-size: 18px;
  font-family: MazzardL-Light;
}

.mli-price {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 5px 18px;
  margin-top: 6px;
  color: #ffffff;
  font-size: 15px;
  font-family: MazzardL-Medium;
  white-space: nowrap;
  line-height: 20px;
  background-color: #ce3939;
  border-radius: 100px;
}

.pr-item {
  display: flex;
}

.pr-item:first-child {
  margin-right: 5px;
}

.pr-item:last-child {
  margin-left: 5px;
  font-family: MazzardL-Semibold;
}

.ml-item-right {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  padding-left: 8%;
}

.ml-item-right.active {
  align-self: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
}

.ml-item-image {
  width: 22vw;
  height: 22vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1000px;
}

.ml-item-image.active {
  width: 100%;
  height: 75vw;
  transition: 0.1s;
  border-radius: 2px;
}

.ml-item-right.active>.ml-item-icons {
  position: initial;
}

.ml-item-icons {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  width: fit-content;
  padding: 8px;
  margin-top: 20px;
  border: 1px solid #FFFFFF33;
  border-radius: 100px;
}

.ml-item-icon {
  display: flex;
  width: 9vw;
  height: 9vw;
  margin: 2px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: -2vw;
}

.ml-item-icon.agenda {
  min-width: 80px;
  max-width: 80px;
  min-height: 60px;
  background-size: 45px;
}

.ml-item-icon.vegan {
  background-image: url("../../assets/images/vegan.png");
}

.ml-item-icon.hot {
  background-image: url("../../assets/images/hot.png");
}

.ml-item-icon.hothot {
  background-image: url("../../assets/images/hothot.png");
}

.ml-item-icon.new {
  background-image: url("../../assets/images/new.png");
}

.ml-item-icon.like {
  background-image: url("../../assets/images/like.png");
}

.order-cta {
  display: flex;
  align-items: center;
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: calc(100% - (20px * 2));
  height: 70px;
  padding: 0 20px;
  border: none;
  border-radius: 10px;
  background: #FDFAECF2;
  backdrop-filter: blur(4px);
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.25);
  animation: fadeIn 0.2s ease;
}

.order-cta-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;
}

.order-cta-content-title {
  color: #000;
  font-size: 14px;
  font-family: CraftworkGrotesk-Bold;
  text-align: left;
}

.order-cta-content-description {
  color: #000;
  margin-top: 2px;
  font-size: 12px;
  font-family: CraftworkGrotesk-Medium;
  text-align: left;
  line-height: 16px;
}

.order-cta-content-description span {
  font-family: CraftworkGrotesk-Bold;
}

.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #ffffff;
  z-index: 100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-image: url("../../assets/images/arrowup.png");
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.1);
}

@media screen and (min-width: 600px) {
  .page-head {
    width: 50%;
    margin: 0 auto;
  }

  .order-cta {
    width: 40%;
    left: 30%;
  }

  .new-menu {
    margin-top: 20px;
  }

  .quick-links-wrapper {
    width: 50%;
    margin: 0 auto;
    padding: 0;
  }

  .quick-links {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    overflow-x: hidden;
  }

  .newmenu-list {
    width: 50%;
    margin: 0 auto;
  }

  .embla__slide {
    width: 100%;
  }

  .newmenu-chunk-title {
    padding: 0 3%;
    font-size: 34px;
  }

  .newmenu-chunk {
    padding: 0;
  }

  .newmenu-chi-image {
    width: 15vw;
    height: 15vw;
  }

  .newmenu-chi-title {
    font-size: 20px;
  }

  .newmenu-chi-desc {
    font-size: 16px;
  }

  .ml-item-icon {
    width: 30px;
    height: 30px;
    margin-left: 0;
  }
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
