.vacancies-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: #FFFDF6;
  overflow-x: hidden;
}

.vacancies-page .page-title {
  color: #000000;
}

.vacancies-page .page-icon.back {
  transform: rotate(0deg);
  background-image: url('../../assets/images/arrow-left.png');
}


.vacancy-item {
  width: 90%;
  height: 200px;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.5);
}

.vacancy-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(100.11deg, rgba(158, 49, 49, 0.8) 13.69%, rgba(255, 178, 106, 0.8) 91.66%);
}

.vacancy-title {
  color: #FFFFFF;
  font-size: 30px;
  font-family: EuclidCircularA-LightItalic;
  text-align: center;
}

.vacancy-caption {
  position: absolute;
  bottom: 20px;
  padding: 10px 30px;
  border-radius: 4px;
  margin-top: 20px;
  color: #FFF;
  font-size: 14px;
  font-family: EuclidCircularA-Light;
  text-align: center;
}

.vacancy-button-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.5);
  color: #432a2a;
  font-size: 20px;
  font-family: MazzardL-Regular;
  text-align: center;
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.03);
}

.no-vacancy {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  font-size: 20px;
  font-family: EuclidCircularA-Regular;
  text-align: center;
}
