.main-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 100px;
}

.banner {
  position: relative;
  width: 100%;
  padding: 20px;
  background-color: #578FC3;
  color: #FFFFFF;
  font-family: CraftworkGrotesk-Medium;
  line-height: 25px;
  font-size: 16px;
  box-sizing: border-box;
}

.banner.regular {
  color: #343434;
  background-color: #F5CD3D;
}

.banner h3 {
  margin: 0;
  font-size: 14px;
  font-family: CraftworkGrotesk-Bold;
}

.banner span {
  min-width: 40%;
}

.banner p {
  position: absolute;
  bottom: 15px;
  right: 30px;
  font-size: 70px;
}

.customer-logo {
  width: 180px;
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/logo.jpg");
  background-color: #3f3434;
  border-radius: 15px;
}

.customer-title {
  width: 100%;
  margin-top: 30px;
  /* color: #3f3434; */
  font-size: 25px;
  font-family: CraftworkGrotesk-Bold;
  text-align: center;
  text-transform: uppercase;
}

.customer-caption {
  width: 100%;
  margin-top: 3px;
  color: #3f3434;
  font-size: 14px;
  font-family: CraftworkGrotesk-Medium;
  text-align: center;
}

.customer-openhours {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 35px;
  margin-top: 20px;
  height: 30px;
  background-color: #ffffffb3;
  border-radius: 15px;
  font-size: 12px;
  font-family: CraftworkGrotesk-Medium;
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.1);
}

.customer-actions-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin-top: 40px;
}

.c-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  padding: 10px 15px 10px 20px;
  margin-bottom: 10px;
  border-radius: 15px;
  box-sizing: border-box;
  text-decoration: none;
  background: #FFFFFFB3;
  /* backdrop-filter: blur(200px); */
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.05);
  cursor: pointer;
}

.c-action-left {
  display: flex;
  align-items: center;
}

.c-action-flat-icon {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-action-flat-icon.ukr {
  background-image: url("../../assets/images/ua.png");
}

.c-action-flat-icon.gb {
  background-image: url("../../assets/images/gb.png");
}

.c-action-flat-icon.delivery {
  background-image: url("../../assets/images/delivery.png");
}

.c-action-flat-icon.route {
  background-image: url("../../assets/images/route.png");
}

.c-action-flat-icon.jobs {
  background-image: url("../../assets/images/jobs.png");
}

.c-action-flat-icon.phone {
  background-image: url("../../assets/images/phone.png");
}

.c-action-flat-icon.phone {
  background-image: url("../../assets/images/phone.png");
}

.c-action-flat-icon.inst {
  background-image: url("../../assets/images/inst.png");
}

.c-action.disabled {
  opacity: 0.5;
}

.c-action-title {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  /* color: #3f3434; */
  font-size: 15px;
  font-family: CraftworkGrotesk-Medium;
}

.c-action-title span {
  margin-top: 4px;
  font-size: 16px;
  font-family: MazzardL-Light;
  line-height: 16px;
}

.c-action-arrow-icon {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  background-image: url("../../assets/images/arrow.png");
}

.c-action-trash-icon {
  width: 40px;
  height: 40px;
  padding-top: 4px;
  margin-left: 20px;
  border: 1.5px solid transparent;
  border-radius: 100px;
  background-color: transparent;
}

.c-action-divider {
  width: 70%;
  height: 2px;
  margin: 20px 0 30px 0;
}

@media screen and (min-width: 600px) {
  .main-page {
    width: 30%;
    margin: 0 auto;
  }

  .banner {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .banner div {
    width: 28%;
  }
}
