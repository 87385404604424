.order.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.checkout__done {
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/done.svg");
}

.checkout__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 100px;
  overflow-x: hidden;
}

@media screen and (min-width: 600px) {
  .checkout__content {
    width: 30%;
    padding-top: 50px;
    margin: auto;
  }
}

.checkout__content_title {
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  margin-bottom: 20px;
  color: #000;
  font-size: 18px;
  font-family: CraftworkGrotesk-Bold;
}

.checkout__content_caption {
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  margin-top: 10px;
  line-height: 16px;
  transition: 0.2s ease;
  font-size: 14px;
  font-family: CraftworkGrotesk-Medium;
}

.checkout__content_caption > div {
  font-size: 14px;
  font-family: CraftworkGrotesk-Medium;
  line-height: 18px;
  padding: 10px;
  border-radius: 6px;
  white-space: pre-line;
  color: #643E1C;
  border: 1px solid #643E1C66;
  background-color: #B0903011;
}

.checkout__content_caption.one {
  height: 68px;
}

/* .checkout__content_caption.one > div {
  color: #15314B;
  border: 1px solid #15314B66;
  background-color: #568FC311;
} */

.checkout__content_caption.two {
  height: 98px;
}

.checkout__content_caption.two > div {
  color: #6C1919;
  border: 1px solid #6C191966;
  background-color: #CE393911;
}

.checkout__content_caption span {
  padding-left: 5px;
  color: #000000;
  font-size: 16px;
  font-family: CraftworkGrotesk-Bold;
}

.checkout__content_tabs {
  display: flex;
  padding: 0 20px;
  margin-bottom: 10px;
}

.checkout__content_tabs_tab {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  margin-right: 10px;
  color: #000;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1.5px solid transparent;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(200px);
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.09);
  font-size: 12px;
  font-family: CraftworkGrotesk-Medium;
}

.checkout__content_tabs_tab.active:first-child.active {
  border: 1.5px solid #568FC3;
}

.checkout__content_tabs_tab.active:last-child.active {
  border: 1.5px solid #CE3939;
}

.checkout__content_tabs_tab_icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.checkout__content_tabs_tab_icon.selfservice {
  background-image: url("../../assets/images/selfservice.png");
}

.checkout__content_tabs_tab_icon.taxi {
  background-image: url("../../assets/images/taxi.png");
}

.checkout__content_fields {
  width: 100%;
  margin-top: 20px;
}

.checkout__content_fields_inputcontainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 15px;
  overflow: visible;
}

.checkout__content_fields_inputcontainer.error input {
  border-color: #CE3939;
}

.checkout__content_fields_inputcontainer label {
  font-size: 14px;
  font-family: CraftworkGrotesk-Medium;
}

.checkout__content_fields_inputcontainer label span {
  color: #CE3939;
}

.checkout__content_fields_inputcontainer input {
  margin-top: 5px;
  padding: 15px;
  border: 1px solid #CCCCCC;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(200px);
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.05);
  color: #000;
  font-size: 16px;
  font-family: CraftworkGrotesk-Medium;
}

.checkout__content_fields_inputcontainer span {
  border: none;
  padding: 10px 0;
  font-size: 16px;
  font-family: CraftworkGrotesk-Bold;
  background: transparent;
  box-shadow: none;
}

.checkout__content_fields_inputcontainer input:disabled {
  opacity: 1;
}

.checkout__content_fields_inputcontainer textarea {
  margin-top: 5px;
  padding: 15px;
  min-height: 40px;
  border: 1px solid #CCCCCC;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(200px);
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.05);
  font-size: 16px;
  font-family: CraftworkGrotesk-Medium;
}

.checkout__content_fields_inputcontainer_dropdown {
  position: absolute;
  top: 80px;
  width: calc(100vw - 20px * 2);
  max-height: 200px;
  background: #FEFCF5;
  border: 1px solid #CCCCCC;
  box-shadow: 0 10px 30px 0 rgba(0, 38, 62, 0.1);
  animation: fadeIn 0.2s ease;
  overflow: scroll;
  z-index: 100;
}

.checkout__content_fields_inputcontainer_dropdown_item {
  width: 100%;
  padding: 15px;
  color: #000;
  font-size: 16px;
  font-family: CraftworkGrotesk-Medium;
  border: none;
  border-bottom: 1px solid #CCCCCC66;
  background-color: transparent;
  text-align: left;
}

.checkout__content_divider {
  width: 100%;
  height: 1px;
  width: calc(100% - 20px * 2);
  margin: 10px auto 20px auto;
  background-color: #CCCCCC55;
}

.checkout__content_submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px * 2);
  height: 50px;
  margin: 0 auto;
  margin-top: 40px;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-family: CraftworkGrotesk-Medium;
}

.checkout__content_submit:disabled {
  opacity: 0.5;
}

.checkout__content_submit.blue {
  animation: fromRedToBlue 0.5s forwards;
}

.checkout__content_submit.red {
  animation: fromBlueToRed 0.5s forwards;
}

@keyframes fromBlueToRed {
  from {background-color: #568FC3;}
  to {background-color: #CE3939;}
}

@keyframes fromRedToBlue {
  from {background-color: #CE3939;}
  to {background-color: #568FC3;}
}

.checkout__content_cart {
  padding: 0 20px;
  margin-bottom: 20px;
}

.checkout__content_cart_item {
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid #CCCCCC33;
  font-size: 14px;
  font-family: CraftworkGrotesk-Medium;
}

.checkout__content_cart_item > div {
  display: flex;
  flex-direction: row;
}

.checkout__content_cart_item:last-child {
  border-bottom: none;
}

.checkout__content_cart_item_qty {
  width: 15%;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.checkout__content_cart_item_title {
  display: flex;
  align-items: center;
  width: 62%;
  padding-right: 10px;
  font-size: 15px;
}

.checkout__content_cart_item_price {
  display: flex;
  align-items: center;
  min-width: 6ch;
  font-size: 16px;
  font-family: CraftworkGrotesk-Bold;
}

.checkout__content_cart_item_delete {
  padding: 8px 8px 6px 8px;
  background-color: transparent;
  background-color: transparent;
  border: none;
}

.checkout__content_cart_item_slider {
  width: 90%;
  margin: 20px auto 10px auto;
}

.checkout__content_cart_item_slider-caption {
  margin: 5px auto;
  color: #292929;
  font-size: 12px;
  font-family: CraftworkGrotesk-Bold;
  text-transform: uppercase;
}

.ant-slider-mark-text {
  display: none !important;
  font-size: 14px;
  font-family: CraftworkGrotesk-Medium;
}

.swiper {
  overflow: visible !important;
}
