html {
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #FFFDF6;
}

.gradient {
  /* background: radial-gradient(82.62% 82.62% at -31.9% 17.38%, #F8F5F3 0%, rgba(255, 186, 186, 0) 100%),
    linear-gradient(0deg, #fff6e099, #fff6e044); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 15px 0;
  cursor: pointer;
}

.page-title {
  color: #000000;
  font-size: 20px;
  font-family: CraftworkGrotesk-Medium;
}

.page-icon {
  width: 30px;
  height: 30px;
  margin: 0 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}

.page-icon.back {
  /* transform: rotate(180deg); */
  background-image: url('./assets/images/arrow-left.png');
}

.page-nav {
  display: flex;
  align-items: center;
  height: 100%;
}

.page-nav .page-title {
  padding-right: 20px;
  margin-left: -10px;
  font-size: 16px;
  font-family: CraftworkGrotesk-Medium;
  line-height: 20px;
}

@media screen and (min-width: 600px) {
  .page-icon {
    margin: 0 20px 0 0;
  }
}


@font-face {
  font-family: MazzardL-Light;
  src: url("./assets/fonts/MazzardL-Light.ttf");
}

@font-face {
  font-family: MazzardL-Regular;
  src: url("./assets/fonts/MazzardL-Regular.ttf");
}

@font-face {
  font-family: MazzardL-Medium;
  src: url("./assets/fonts/MazzardL-Medium.ttf");
}

@font-face {
  font-family: MazzardL-SemiBold;
  src: url("./assets/fonts/MazzardL-SemiBold.ttf");
}

/* <--------------------------------------------> */

@font-face {
  font-family: EuclidCircularA-Light;
  src: url("./assets/fonts/EuclidCircularA-Light.ttf");
}

@font-face {
  font-family: EuclidCircularA-LightItalic;
  src: url("./assets/fonts/EuclidCircularA-LightItalic.ttf");
}

@font-face {
  font-family: EuclidCircularA-Regular;
  src: url("./assets/fonts/EuclidCircularA-Regular.ttf");
}

@font-face {
  font-family: EuclidCircularA-Medium;
  src: url("./assets/fonts/EuclidCircularA-Medium.ttf");
}

@font-face {
  font-family: EuclidCircularA-MediumItalic;
  src: url("./assets/fonts/EuclidCircularA-MediumItalic.ttf");
}

@font-face {
  font-family: EuclidCircularA-SemiBold;
  src: url("./assets/fonts/EuclidCircularA-SemiBold.ttf");
}

@font-face {
  font-family: CraftworkGrotesk-Medium;
  src: url("./assets/fonts/CraftworkGrotesk-Medium.ttf");
}

@font-face {
  font-family: CraftworkGrotesk-Bold;
  src: url("./assets/fonts/CraftworkGrotesk-Bold.ttf");
}

@font-face {
  font-family: CraftworkGrotesk-Heavy;
  src: url("./assets/fonts/CraftworkGrotesk-Heavy.ttf");
}
