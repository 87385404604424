.app {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: visible;
}

.hero-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  background: #4273CD;
}

.hero-banner-half {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  background: #4273CD;
  z-index: 1;
}

.hero-banner-half.yellow {
  background: #FEE15A;
}

p {
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  font-size: 16px;
  font-family: MazzardL-Medium;
  text-transform: uppercase;
  z-index: 2;
}